/*
Template Name: Sipyo -  Admin & Dashboard Template
Author: Themesdesign
Version: 1.3.0
Contact: themesdesign.in@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/root";
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/forms";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/apexcharts";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/calendar";
@import "custom/plugins/color-picker";
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/datatable";
@import "custom/plugins/datepicker";
@import "custom/plugins/flot";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/google-map";
@import "custom/plugins/parsley";
@import "custom/plugins/range-slider";
@import "custom/plugins/rating";
@import "custom/plugins/responsive-table";
@import "custom/plugins/select2";
@import "custom/plugins/session-timeout";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/switch";
@import "custom/plugins/table-editable";
@import "custom/plugins/vector-maps";
@import "custom/plugins/x-editable";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/email";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";

/* ! Menu Management */
@media screen and (min-width: 937px) {
    .menuManagement {
        display: grid;
        grid-template-columns: 30% 70%;
    }
}

@media screen and (max-width: 936px) {
    .menuManagement {

    }
}

.rotate-icon {
    transition: transform 0.3s ease;
}

.rotate-icon.rotated {
    transform: rotate(180deg);
}

.btn-xxxl {
    font-size: 18px;
    line-height: 1.5;
    height: 55px;
    width: 95%;
    padding: 7px 15px;
    border-radius: 8px;
}

.btn-xxxl .ant-btn-icon {
    width: 45px;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-btn {
    font-weight: 500 !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    border-color: transparent !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border-color: transparent !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-switch.ant-switch-checked {
    background: #c72328 !important;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-checkbox-checked .ant-checkbox-inner {
    background-color: #c72328 !important;
    border-color: #c72328 !important;
    
}

:where(.css-dev-only-do-not-override-1k979oh).ant-input-group >.ant-input:first-child, :where(.css-dev-only-do-not-override-1k979oh).ant-input-group .ant-input-group-addon:first-child {
    min-height: 40px !important;
}
